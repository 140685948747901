import React from 'react';
import { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import { Root, Button, AppRoot, View, Panel, PanelHeader, Header, Group, Cell, CellButton, PanelHeaderBack, Search, Avatar, Spinner, PanelHeaderSubmit, Div, Alert, Placeholder, 
Text, ScreenSpinner, ActionSheet, ActionSheetItem, AdaptivityProvider, ConfigProvider, ModalRoot, ModalPage, FormLayout, Tabbar, 
TabbarItem, Counter, Badge, ModalCard, Snackbar, Input, Textarea, WriteBar, WriteBarIcon, ModalPageHeader, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28GlobeOutline, Icon48Play, Icon28UserCircleOutline, Icon24ErrorCircleOutline, Icon24MenuOutline, Icon56GestureOutline, Icon28NewsfeedOutline, Icon28MessageOutline, Icon28ClipOutline, Icon28HelpOutline, Icon56UsersOutline, 
Icon28DoneOutline, Icon28SmileOutline, Icon24Dismiss } from '@vkontakte/icons';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import '@vkontakte/vkui/dist/vkui.css';

const queryString = require('query-string');


var debug = false;
var host = "appsock.inmsk.net:8000";
//var host = "127.0.0.1:8000";

class core extends React.Component {
	constructor(props) {
		super(props);
		this.firstRun = false;
		this.socket = 0;
		this.host = host;
		this.connect = this.connect.bind(this);
		this.query = this.query.bind(this);
		this.bindSocket = this.bindSocket.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	onChange(e) {
		const { name, value } = e.currentTarget;
		this.setState({ [name]: value });
	}
	vkGet(method,props={},callback=function(){}) {
		var g_this = this;
		bridge.send(method,props).then(data => {
			callback(data);
		}).catch(error => {
			g_this.info("error get","error");
		});
	}
	connect(callback=function(){}) {
		if (this.socket)
			return;
		var g_this = this;
		this.wait();
		this.socket = new WebSocket("wss://"+this.host);
		this.socket.onopen = function() {
			callback();
			g_this.setState({popout: null});
		};
		this.socket.onclose = function(event) {
			g_this.socket = false;
			g_this.setState({ popout:
				<Alert
					actions={[{
					title: 'Подключиться',
					autoclose: true,
					mode: 'destructive',
					action: () => g_this.initConn(),
					}]}
					actionsLayout="horizontal"
					onClose={g_this.closePopout}
					header="Связь с сервером потеряна"
				/>
			});
		};
		this.bindSocket();
	}
	bindSocket() {
		var g_this = this;
		this.socket.onmessage = function(event) {
			var response = JSON.parse(event.data);
			if (response.status) {
				if (response.snackbar===true) {
					g_this.setSnackbar(response.message);
				}
				if (response.chat===true) {
					console.log(response);
					g_this.state.listChat.push(<Cell after={<Avatar size={20} src={response.message.ava1}/>}>
						<Div>{response.message.message}</Div>
					</Cell>);
					g_this.setState({listChat:g_this.state.listChat});
				}
			}
		};
	}
	// query_old(fnc,data={},callback=function(){}) {
		// data = {"f":fnc,"vid":this.state.userInf.id,"vkey":this.state.vkey,"data":data}
		// var g_this = this;
		// this.wait();
		// fetch(url, {
		// method: 'POST',  
		// body: JSON.stringify(data),  
		// headers:{
		// 'Content-Type': 'application/json;charset=utf-8'
		// }
		// }).then(res => res.json())
		// .then(response => {
			// if (response.status==false) {
				// g_this.info(response.message,"error");
				// return;
			// }
			// g_this.setState({ popout: null});
			// callback(response);
		// })
		// .catch(error => {
			// g_this.info("error connect","error");
		// });
	// }
	query(fnc,data={},callback=function(){}) {
		if (this.socket.readyState===1) {
			data = {"f":fnc,"vid":this.state.userInf.id,"vkey":this.state.vkey,"data":data}
			var g_this = this;
			this.wait();
			this.socket.send(JSON.stringify(data));
			this.socket.onmessage = function(event) {
				var response = JSON.parse(event.data);
				if (response.status===false) {
					g_this.info(response.message,"Ошибка");
					return;
				}
				callback(response);
				g_this.bindSocket();
				
				g_this.setState({popout: null});
				return;
			};
		}else
			this.info("Нет подключения к серверу","Ошибка");
	}
}

class App extends core {
	constructor(props) {
		super(props);
		bridge.send('VKWebAppInit');
		this.state = {
			nameApp: "С кем покатать",
			activePanel: 'home',
			userInf: {},
			vkey: 0,
			id: 0,
			initApp: {},
			initHash: '',
			activeModal: null,
			popout: null,
			snackbar: null,
			listUsers: [],
			listPoss: [],
			selectUser: {},
			pos: [55.75,37.6167],
			desForRun: '',
			textMessage: '',
			listChat: []
		};
		this.state.initApp = queryString.parse(window.location.search);
		if (window.location.hash)
			this.state.initHash = window.location.hash;
		var g_this = this;
		//debug
		if (debug) {
			this.state.userInf = {id:"12345",first_name:"test"};
			this.state.vkey = '68c26371541a99e2afe154524b0b08f8';
		}
		//debug
		
		
		
		this.vkGet("VKWebAppStorageGet",{"keys":["vkey"]},function(data){
			g_this.setState({ vkey: data.keys[0].value});
		});
		

		this.vkGet("VKWebAppGetGeodata",{},function(data){
			g_this.setState({pos: [data.lat,data.long]});
		});

		this.closePopout = this.closePopout.bind(this);
		this.closeModal = this.closeModal.bind(this);
		
	}
	//Функция первого запуска
	start() {
		if (this.firstRun)
			return;
		this.initConn();
		if (this.state.initHash) {
			var hashArr = this.state.initHash.split("_");
			switch(hashArr[0]) {
				case '#message':
					//this.openChat(hashArr[1]);
				break;
				default:
				
				break;
			}
		}
		this.firstRun = true;
	}
	//Полное подключение
	initConn(callback=function(){}) {
		var g_this = this;
		this.connect(function(){
			//debug
			if (debug) {
				g_this.query("start",g_this.state.userInf,function(data2){
					g_this.state.id = data2.id;
					g_this.setState({id:data2.id, popout: null});
					callback();
				});
			}
			//debug
			
			g_this.vkGet("VKWebAppGetUserInfo",{},function(data){
				g_this.setState({userInf: data});
				g_this.query("start",data,function(data2){
					if (data2.new_user) {
						g_this.vkGet("VKWebAppStorageSet",{"key": "vkey","value": data2.new_user},function(){
							g_this.setState({vkey: data2.new_user});
						});
					}
					g_this.setState({id:data2.id, popout: null});
					callback();
				});
			});
		});
	}
	
	
	

	closePopout () {
		this.setState({ popout: null });
	}
	closeModal () {
		this.setState({ activeModal: null });
	}
	closeWait() {
		
	}
	wait() {
		this.setState({ popout:<ScreenSpinner />});
		setTimeout(() => { this.setState({ popout: null });}, 60000);
	}
	info(text,title="info") {
		this.setState({ popout:
			<Alert
			actions={[{
			title: 'Закрыть',
			autoclose: true,
			mode: 'cancel'
			}]}
			actionsLayout="horizontal"
			onClose={this.closePopout}
			header={title}
			text={text}
			/>
		});
	}
	submit_drive () {
		var g_this = this;
		this.vkGet("VKWebAppGetGeodata",{},function(data){
			g_this.setState({pos: [data.lat,data.long]});
			g_this.query("run",{"vlat":data.lat,"vlong":data.long,"vstatus":1},function(data){
				g_this.setState({ activePanel: "on_map" });
				setTimeout(() => { g_this.setSnackbar("Ок, мы сообщили всем участникам приложения что вы выкатились") }, 1000);
			});
		});	
	}
	
	menuListRiders() {
		return(<Group style={{zIndex:"1"}}>
			<Button mode="tertiary" before={<Icon24MenuOutline/>} onClick={() => this.setState({ activeModal: 'menuTypeList' })}></Button>
		</Group>);
	}
	userInfo(id) {
		var g_this = this;
		this.query("user_info",{"id":id},function(data){
			g_this.setState({ selectUser: {id:id, info: <Cell before={<Avatar src={data.user.ava_2} size={72}/>}><Div>{data.user.name} {data.user.last_name}</Div>
				{Number(g_this.state.id)===Number(data.user.id) ? <Textarea stretched name="desForRun" onChange={g_this.onChange}/> :''}
			</Cell>}, activeModal: 'userInfo' });
		});
	}
	btnModalList(link) {
		var g_this = this;
		this.query(link,{},function(data){
			var ListPoss = [];
			var ListUsers = [];
			data.list.forEach(function(obj,key){
				if (link==="list_riders")
					ListUsers.push(<Cell key={key} before={<Avatar size={40} src={obj.ava_1}/>} onClick={() => g_this.userInfo(obj.id)}>{obj.name}</Cell>);
				if (link==="on_map")
					ListPoss.push(<Placemark key={key} geometry={[obj.vlat,obj.vlong]} properties={{hintContent: obj.name,iconCaption : obj.name}} onClick={() => g_this.userInfo(obj.id)} />);
				
			});
			ListUsers = ListUsers.length>0 ? ListUsers : [<Placeholder
              icon={<Icon56UsersOutline />}
              header="Хм, никого нет :-("
            ></Placeholder>];

			g_this.setState({listPoss:ListPoss,listUsers:ListUsers, activePanel: link});
		});
		this.closeModal();
	}
	setSnackbar(text) {
		this.setState({snackbar:<Snackbar onClose={() => this.setState({ snackbar: null })} before={<Icon28DoneOutline/>}>
			{text}
		</Snackbar>});
	}
	
	
	closeChat(id) {
		this.state.selectUser.id = id ? id : this.state.selectUser.id;
		var g_this = this;
		this.query("close_chat",{id:this.state.selectUser.id},function() {
			g_this.closeModal();
		});
		
	}
	openChat(id) {
		
		this.state.selectUser.id = id ? id : this.state.selectUser.id;
		var g_this = this;
		this.query("get_chat",{id:this.state.selectUser.id},function(data){
			console.log(data);
			var ListChat = [];
			data.list.forEach(function(obj,i){
				console.log(obj.id+" "+g_this.state.id);
				if (obj.id===g_this.state.id)
					ListChat.push(<Cell key={i} after={<Avatar size={20} src={obj.ava1}/>}>
						<Text>{obj.message}</Text>
					</Cell>);
				else
					ListChat.push(<Cell key={i} before={<Avatar size={20} src={obj.ava1}/>}>
						<Text>{obj.message}</Text>
					</Cell>);
				
			});
			
			g_this.setState({listChat:ListChat, activeModal: 'chat'});
		});
	}
	sendChat() {
		var g_this = this;
		this.query("send_chat",{id:this.state.selectUser.id, message:this.state.textMessage},function(data){
			g_this.state.listChat.push(<Cell after={<Avatar size={20} src={data.newMessage.ava1}/>}>
				<Div>{data.newMessage.message}</Div>
			</Cell>);
			g_this.state.textMessage = "";
		});
	}
	componentDidMount() {
		this.start();
	}
	render() {
		
		const AlwaysScrollToBottom = () => {
			const elementRef = useRef();
			useEffect(() => elementRef.current.scrollIntoView());
			this.render();
			return <div ref={elementRef} />;
		};
		const Messages = ({ items }) => (
			<Div>
			{items}
			<AlwaysScrollToBottom />
			</Div>
		)
		const modal = (
			<ModalRoot actionsLayout="horizontal" activeModal={this.state.activeModal} onClose={this.closeModal}>
				<ModalCard id="menuTypeList"
					onClose={this.closeModal}
					header="Где будем искать"
					actionsLayout="vertical"
					actions={[
							<Button key="1" onClick={() => this.btnModalList("list_riders")}>
								Список
							</Button>,
							<Button key="2" onClick={() => this.btnModalList("on_map")}>
								На карте
							</Button>
					]}>
					<Div>Тут какое то описание...</Div>
				</ModalCard>
					
				<ModalCard id="userInfo"
					onClose={this.closeModal}
					header="Карточка юзера"
					actionsLayout="vertical"
					actions={[
							<Button key="1" onClick={() => this.openChat()}>
								Присоединиться
							</Button>
					]}>
					{this.state.selectUser.info}
				</ModalCard>

				<ModalCard id="chat"
					onClose={() => this.closeChat()}
					header={<ModalPageHeader left={<PanelHeaderBack onClick={()=>this.userInfo(this.state.selectUser.id)}/>} right={<PanelHeaderButton><Icon24Dismiss/></PanelHeaderButton>}>Комната покатушки</ModalPageHeader>}
					actionsLayout="vertical"
					actions={[
							
					]}>
					<Group style={{overflowY:"auto"}}>
						<Messages items={this.state.listChat}/>
					</Group>
					<Group>
						<WriteBar
						value={this.state.textMessage}
						name="textMessage"
						onChange={this.onChange}

						after={
						<Fragment>
						{
						<WriteBarIcon>
						  <Icon28SmileOutline />
						</WriteBarIcon>
						}

						<WriteBarIcon
							onClick={() => this.sendChat()}
						  mode="send"
						  disabled={this.state.textMessage.length === 0}
						/>
						</Fragment>
						}
						placeholder="Сообщение"
						/>
					</Group>
				</ModalCard>
			</ModalRoot>
		);
		
	  return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<View activePanel={this.state.activePanel} popout={this.state.popout} modal={modal}>
						<Panel id="on_map">
							<PanelHeader separator={false} left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'home' })}/>}>	
									{this.state.nameApp}	
							</PanelHeader>
							{this.menuListRiders()}
								<YMaps>
									<Map className='mapview' defaultState={{ center: this.state.pos, zoom: 15 }} > 
										<Placemark 
											geometry={this.state.pos}
											properties={{
												hintContent: "Я",
												iconCaption : 'Я'
											}}
											options={{
												preset: "islands#circleDotIcon"
											}}
											onClick={() => this.userInfo(0)}
										/>
										{this.state.listPoss}
									</Map>
								</YMaps>
								{this.state.snackbar}
						</Panel>
						
						<Panel id="list_riders">
							<PanelHeader separator={false} left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'home' })}/>}>{this.state.nameApp}</PanelHeader>
							{this.menuListRiders()}
							<Group header={<Header mode="secondary">Сейчас катают</Header>}>
								<Div>
									{this.state.listUsers}
								</Div>
							</Group>
						</Panel>
						
						<Panel id="info">
							<PanelHeader separator={false} left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'home' })}/>}>{this.state.nameApp}</PanelHeader>
							<Group header={<Header mode="secondary">Как это работает</Header>}>
								<Div>
									<Text>
										Тут будет описание работы приложения
									</Text>
								</Div>
							</Group>
						</Panel>
						
						<Panel id="home">
						  <PanelHeader separator={false} left={<Button before={<Icon28HelpOutline/>} mode="tertiary" onClick={() => this.setState({ activePanel: 'info' })}></Button>}>{this.state.nameApp}</PanelHeader>
						  <Group>
							<Button mode="tertiary" before={<Icon24MenuOutline/>} onClick={() => this.setState({ activeModal: 'menuTypeList' })}>
								Кто сейчас катает?
							</Button>
							<Div style={{position: "absolute",left: 0,top: 0,textAlign:"center",height: "100vh",width: "100vw",padding: 0,zIndex: -1}}>
								<Button before={<Icon56GestureOutline/>} onClick={() => this.submit_drive()} style={{top: "50%"}}>Я катаю!</Button>
							</Div>
						  </Group>
							<Tabbar>
								<TabbarItem text="Тут какая то инфа...">
									<Icon28NewsfeedOutline />
								</TabbarItem>
							</Tabbar>
							{this.state.snackbar}
						</Panel>
					</View>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	  );
	}
};

export default App;
